import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { WaitingRoomPatient, WaitingRoomService } from '@services/api'

export type PatientState = {
  patients: WaitingRoomPatient[]
  calledQueue: WaitingRoomPatient[]
  currentCalled: WaitingRoomPatient | null
  currentCallUrl: string | null
  accompanying: number
}

const initialState: PatientState = {
  patients: [],
  calledQueue: [],
  currentCalled: null,
  currentCallUrl: null,
  accompanying: 0,
}

export enum PatientActions {
  SET_PATIENTS = 'SET_PATIENTS',
  SET_PATIENT = 'SET_PATIENT',
  CALL_PATIENT = 'CALL_PATIENT',
  UNCALL_PATIENT = 'UNCALL_PATIENT',
}

export const updatePatient = createAsyncThunk(
  'patient/updatePatient',
  async (patient: WaitingRoomPatient) => {
    WaitingRoomService.updatePatient({
      body: {
        ...patient,
        status: 'discharged',
        dischargedDate: new Date().getTime(),
      },
    })
  },
)

export const callPatient = createAsyncThunk(
  'patient/callPatient',
  async (patient: WaitingRoomPatient) => {
    WaitingRoomService.callPatient({ body: patient })
  },
)

export const uncallPatient = createAsyncThunk(
  'patient/uncallPatient',
  async (patient: WaitingRoomPatient) => {
    WaitingRoomService.uncallPatient({ body: patient })
  },
)

export const getPatients = createAsyncThunk('patient/getPatient', async (siteId: number) => {
  const patients = await WaitingRoomService.findPatients({ siteId })
  return patients
})

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatients: (state, action: PayloadAction<WaitingRoomPatient[]>) => {
      state.patients = action.payload
    },
    setPatient: (state, action: PayloadAction<WaitingRoomPatient>) => {
      const index = state.patients.findIndex(_patient => _patient.id === action.payload.id)
      state.patients[index] = action.payload
    },
    setQueue: (
      state,
      action: PayloadAction<{
        calledQueue: WaitingRoomPatient[]
        currentCalled: WaitingRoomPatient | null
      }>,
    ) => {
      state.calledQueue = action.payload.calledQueue
      if (state.currentCalled?.id !== action.payload.currentCalled?.id) {
        state.currentCalled = action.payload.currentCalled
      }
    },
    setCurrentCallUrl: (state, action: PayloadAction<string>) => {
      state.currentCallUrl = action.payload
    },
    setAccompanying: (state, action: PayloadAction<number>) => {
      state.accompanying = action.payload
    },
    clean: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(updatePatient.fulfilled, (state, action: PayloadAction<any>) => {
        const patient = action.payload
        const patients = state.patients.map(_patient =>
          _patient.id === patient.id ? patient : _patient,
        )
        state.patients = patients
      })
      .addCase(getPatients.fulfilled, (state, action: PayloadAction<any>) => {
        state.patients = action.payload
      })
  },
})

export const {
  setPatients,
  setPatient,
  clean,
  setQueue,
  setCurrentCallUrl,
  setAccompanying,
} = patientSlice.actions

export default patientSlice.reducer
