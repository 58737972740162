import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Site, SiteService } from '../../services/api'

export interface SiteState {
  sites: Site[]
  selectedSite: Site | null
}

const initialState: SiteState = {
  sites: [],
  selectedSite: null,
}

export const findSites = createAsyncThunk(
  'site/find',
  async (args: { limit: number; skip: number }) => {
    const sites = await SiteService.findAll(args)
    return sites
  },
)

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSites: (state, action: PayloadAction<Site[]>) => {
      state.sites = action.payload
    },
    setSelectedSite: (state, action: PayloadAction<number>) => {
      const site = state.sites.find(_site => _site.id === action.payload) || null
      state.selectedSite = site
    },
  },
  extraReducers: {
    [findSites.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
      console.log({ data: action.payload.datas })

      state.sites = action.payload.datas
    },
  },
})

export const { setSites, setSelectedSite } = siteSlice.actions

export default siteSlice.reducer
