import { createTheme } from '@material-ui/core'
import { pink } from '@material-ui/core/colors'

export default createTheme({
  palette: {
    // type: 'dark',
    primary: {
      main: '#009DA0',
      light: '#e0f2f1',
      dark: '#016D70',
      contrastText: '#fff',
    },
    secondary: {
      main: pink[500],
      dark: '#b0003a',
      light: '#ff6090',
      contrastText: '#fff',
    },

    // text: {
    //   // primary: '#464855',
    //   primary: '#000',
    //   secondary: '#fff',
    // },
  },
  typography: {
    fontFamily: [
      'Rubik',
      'Arial',
      'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontStyle: 'medium',
      fontSize: 200,
    },
    h2: {
      fontStyle: 'medium',
      fontSize: 60,
    },
    h3: {
      fontStyle: 'regular',
      fontSize: 40,
    },
    h4: {
      fontStyle: 'regular',
      fontSize: 30,
    },
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },

    MuiContainer: {
      root: {
        display: 'flex',
      },
    },
    MuiIcon: {
      root: {
        overflow: 'visible',
      },
    },
    MuiButton: {
      root: {
        height: 50,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        fontSize: '1rem',
        textTransform: 'uppercase',
        padding: '0px 15px 0px 15px',
      },
      stickyHeader: {
        color: '#fff',
        padding: '10px',
        backgroundColor: '#3a3a3a',
        borderTop: '2px solid #3a3a3a',
        borderBottom: '2px solid #3a3a3a',
        '&:nth-child(1)': {
          borderLeft: '2px solid #3a3a3a',
          borderRadius: '0px 0px 0px 10px',
        },
        '&:nth-last-child(1)': {
          borderRight: '2px solid #3a3a3a',
          borderRadius: '0px 0px 10px 0px',
        },
      },
      body: {
        borderTop: '2px solid #3a3a3a',
        borderBottom: '2px solid #3a3a3a',
        '&:nth-child(1)': {
          borderLeft: '2px solid #3a3a3a',
          borderRadius: '10px 0px 0px 10px',
        },
        '&:nth-last-child(1)': {
          borderRight: '2px solid #3a3a3a',
          borderRadius: '0px 10px 10px 0px',
        },
      },
    },

    MuiTabs: {
      flexContainer: {
        backgroundColor: '#e2e2e2',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0 10px',
        overflow: 'auto',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f8f8',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#c0e2e2a0',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: 'grey',
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      fullWidth: true,
    },
  },
})
