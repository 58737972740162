export type PatientState = {
  patients: Patient[]
  called: Patient[]
}

export const initialPatientState: PatientState = {
  patients: [],
  called: [],
}

export enum PatientActions {
  // GET_PATIENTS = 'GET_PATIENTS',
  // GET_PATIENT_TICKET_NUMBER = 'GET_PATIENT_TICKET_NUMBER',
  // PUT_PATIENT = 'PUT_PATIENT',
  SET_PATIENTS = 'SET_PATIENTS',
  SET_PATIENT = 'SET_PATIENT',
  CALL_PATIENT = 'CALL_PATIENT',
  UNCALL_PATIENT = 'UNCALL_PATIENT',
  // REMOVE_PATIENT = 'REMOVE_PATIENT',
}

export function patientReducer(state: PatientState, action: AnyAction<PatientActions>) {
  switch (action.type) {
    case PatientActions.SET_PATIENTS:
      return { ...state, patients: action.payload.patients }
    case PatientActions.SET_PATIENT: {
      const { patient } = action.payload
      const patients = state.patients.map(_patient =>
        _patient.id === patient.id ? patient : _patient,
      )
      return { ...state, patients }
    }
    case PatientActions.CALL_PATIENT:
      return { ...state, called: [...state.called, action.payload.called] }
    case PatientActions.UNCALL_PATIENT:
      return {
        ...state,
        called: state.called.filter(called => called.id !== action.payload.called.id),
      }
    // case PatientActions.GET_PATIENTS:
    //   return { ...state, patient: action.payload }
    default:
      return state
  }
}
