import { Typography, TypographyProps } from '@material-ui/core'

type TextProps = TypographyProps & {
  text: string
}
/**
 * Display text with Typography
 * @param text string
 * @param ...props TypographyProps
 *
 */
export function Text({ text, ...props }: TextProps) {
  return <Typography {...props}>{text}</Typography>
}
