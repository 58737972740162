import { createContext, useEffect, useReducer } from 'react'
import { SiteState, SiteActions, siteReducer, initialState } from '../reducers/siteReducer'

export type SiteContext = {
  //TODO rename this back to state
  siteState: SiteState
  dispatch: React.Dispatch<AnyAction<SiteActions>>
}

//@ts-ignore
export const SiteContext = createContext<SiteContext | undefined>()

export function SiteContextProvider({ children }: any) {
  const [state, dispatch] = useReducer(siteReducer, initialState)

  useEffect(() => {
    // TODO Get list of sites
  }, [])

  // return site ? (
  return (
    <SiteContext.Provider
      value={{
        siteState: state,
        dispatch,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
