import io from 'socket.io-client'
import StorageService from './storage'

const socket = io(process.env.REACT_APP_API_URL as string, {
  auth: {
    token: StorageService.getAuthToken(),
  },
  transports: ['websocket'],
  upgrade: false,
})
socket.connect()
socket.on('error', () =>
  setTimeout(() => {
    console.log('reconnecting')

    socket.connect()
  }, 1000 * 2),
)
socket.on('connect', () => console.log('connected'))
socket.on('disconnect', () => {
  socket.connect()
})

export { socket }
