import { combineReducers } from '@reduxjs/toolkit'
import alertReducer from './alertReducer'

import authReducer from './authReducer'
import patientReducer from './patientReducer'
import questionReducer from './questionReducer'
import roomReducer from './roomReducer'
import siteReducer from './siteReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  patient: patientReducer,
  site: siteReducer,
  room: roomReducer,
  alert: alertReducer,
  question: questionReducer,
})

export default rootReducer
