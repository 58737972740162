//TODO this is a security issue
import pkg from '../package.json'
console.log(`version: ${pkg.version}`)
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './services/axios'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from '@utils/theme'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './state/store'
import { SiteContextProvider } from '@contexts/SiteContext'
import { MemberContextProvider } from './contexts'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

Sentry.init({
  dsn:
    'https://glet_d4d00bc9bc39d47e22b01561e32c7908@gitlab.com/api/v4/error_tracking/collector/29055936',
  integrations: [new Integrations.BrowserTracing()],
  environment: 'development',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider>
      <MemberContextProvider>
        <SiteContextProvider>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </PersistGate>
        </SiteContextProvider>
      </MemberContextProvider>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
