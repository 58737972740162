import { WaitingRoomPatient, WaitingRoomStatusEnum } from '@services/api'

export const isInWaitingRoom = (patient: WaitingRoomPatient) => {
  return (
    patient.waitingRoomStatus === WaitingRoomStatusEnum.IN_WAITING_ROOM ||
    patient.waitingRoomStatus === WaitingRoomStatusEnum.IN_EXAM ||
    patient.waitingRoomStatus === WaitingRoomStatusEnum.PENDING_RESCHEDULE ||
    patient.waitingRoomStatus === WaitingRoomStatusEnum.PENDING_RESULT ||
    patient.waitingRoomStatus === WaitingRoomStatusEnum.PREPARING_FOR_EXAM ||
    patient.waitingRoomStatus === WaitingRoomStatusEnum.RESULT_OK ||
    patient.waitingRoomStatus === WaitingRoomStatusEnum.WITH_DOCTOR
  )
}
