import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Variant = 'error' | 'info' | 'default' | 'warning' | 'success'

type Snackbar = {
  message: string
  key: number
  options: {
    key: number
    variant?: Variant
    action: (key: number) => JSX.Element
  }
  dismissed: boolean
  displayed: boolean
}

type AlertState = {
  notifications: Snackbar[]
}

const initialState: AlertState = {
  notifications: [],
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer: (state, { payload }: PayloadAction<any>) => {
        state.notifications.push(payload)
      },
      prepare({ message, type }) {
        const notification = {
          message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: type,
          },
        }

        const payload = {
          ...notification,
          key: new Date().getTime() + Math.random(),
        }
        return { payload }
      },
    },
    closeSnackbar(state, action: PayloadAction<any>) {
      const { key, dismissAll } = action.payload
      state.notifications = state.notifications.map(notification =>
        dismissAll || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification },
      )
    },
    removeSnackbar(state, { payload }: PayloadAction<number>) {
      state.notifications = state.notifications.filter(
        notification => Math.ceil(notification.key) !== Math.ceil(payload),
      )
    },
  },
})

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = alertSlice.actions
export default alertSlice.reducer
