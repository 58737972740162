import imgn1 from '../assets/img/imgn1.png'
import imgn2 from '../assets/img/imgn2.png'
import imgn3 from '../assets/img/imgn3.png'
import imgn4 from '../assets/img/imgn4.png'
import imgn5 from '../assets/img/imgn5.png'
import imgn6 from '../assets/img/imgn6.png'
import imgn7 from '../assets/img/imgn7.png'
import ichat from '../assets/img/chat.png'
import { SexEnum, WaitingRoomStatusEnum } from '@services/api'

export const patientStatus: { [key in WaitingRoomStatusEnum]: string } = {
  registered: 'Accueilli',
  'in-waiting-room': "En Salle d'attente",
  'in-exam': 'En Examen',
  'with-doctor': 'Entretien médecin',
  'saw-doctor': 'Médecin vu',
  'pending-result': 'En attente de résultats',
  'result-ok': 'Résultats disponibles',
  discharged: 'Libéré',
  cancel: 'Annulé',
  // patientConsent: 'Consentement OK',
  planned: 'Plannifié',
  [WaitingRoomStatusEnum.PREPARING_FOR_EXAM]: "Préparation pour l'examen",
  [WaitingRoomStatusEnum.PENDING_RESCHEDULE]: 'A repositionner',
  [WaitingRoomStatusEnum.EXAM_CANCELED]: 'Examen annulé',
}

export const patientSex: { [key in SexEnum]: string } = {
  M: 'H',
  F: 'F',
  A: 'A',
  N: 'N',
  O: 'O',
  U: 'U',
}

export const icons = [
  { icon: 'fas fa-sun', day: '01d', night: '01n', main: 'clear sky' },
  { icon: 'fas fa-cloud', day: '02d', night: '02n', main: 'few clouds' },
  { icon: 'fas fa-cloud', day: '03d', night: '03n', main: 'scattered clouds' },
  { icon: 'fas fa-cloud', day: '04d', night: '04n', main: 'broken clouds' },
  {
    icon: 'fas fa-cloud-showers-heavy',
    day: '09d',
    night: '09n',
    main: 'shower rain',
  },
  { icon: 'fas fa-cloud-rain', day: '10d', night: '10n', main: 'rain' },
  { icon: 'fas fa-bolt', day: '11d', night: '11n', main: 'thunderstorm' },
  { icon: 'far fa-snowflake', day: '13d', night: '13n', main: 'snow' },
  { icon: 'fas fa-fog', day: '50d', night: '50n', main: 'mist' },
]

export const getPatientIconInfo: { [key: string]: string } = {
  dementia: imgn1,
  visuallyImpaired: imgn2,
  blind: imgn3,
  crutches: imgn4,
  stretcher: imgn5,
  wheelchair: imgn6,
  deaf: imgn7,
  chat: ichat,
}

export const listNameIcon = [
  'dementia',
  'visuallyImpaired',
  'blind',
  'crutches',
  'stretcher',
  'wheelchair',
  'deaf',
  'chat',
]
