import { Site } from '@services/api'

export interface SiteState {
  sites: Site[]
  selectedSite: Site | null
}

export const initialState: SiteState = {
  sites: [],
  selectedSite: null,
}

export enum SiteActions {
  FIND_SITES = 'FIND_SITES',
  SET_SELECTED_SITE = 'SET_SELECTED_SITE',
}

export function siteReducer(state = initialState, action: AnyAction<SiteActions>): SiteState {
  let selectedSite: Site | undefined
  switch (action.type) {
    case SiteActions.FIND_SITES:
      return { ...state, sites: action.payload }

    case SiteActions.SET_SELECTED_SITE:
      selectedSite = state.sites.find(_state => _state.id === action.payload)

      return { ...state, selectedSite: selectedSite || null }
  }
}
