import {
  Backdrop,
  Box,
  CircularProgress,
  CircularProgressProps,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
)

export const GridLoading = () => (
  <div className="loading-shading-mui">
    <CircularProgress className="loading-icon-mui" />
  </div>
)

export const Spinner = ({ size = 50, ...props }: CircularProgressProps) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress size={size} color="secondary" {...props} />
    </Backdrop>
  )
}

interface Props {
  value: number
}

export const LinearProgressWithLabel = (props: Props) => {
  const { value } = props
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  )
}
