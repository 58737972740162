import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

// import { store } from '@state/store'
// import { ErrorActions } from '@state/actions'
// import { logout } from '@state/actions/authActions'

import { serviceOptions } from './api'
import StorageService from './storage'

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(onRequestIntercepted, onRequestRejected)
instance.interceptors.response.use(onResponseIntercepted, onResponseRejected)

function onRequestIntercepted(config: AxiosRequestConfig) {
  const token = StorageService.getAuthToken()
  if (token) config.headers['Authorization'] = 'Bearer ' + token
  return config
}

function onResponseIntercepted(res: AxiosResponse) {
  if (res.headers['content-disposition']) {
    console.log(res.headers['content-disposition'])
    const filename = res.headers['content-disposition'].split('filename=')[1]
    console.log(filename)
    res.data = { data: URL.createObjectURL(res.data), filename }
  }
  return res
}

function onRequestRejected(error: any) {
  return Promise.reject(error)
}

function onResponseRejected(error: any) {
  const axiosError: AxiosError = error
  // const { dispatch } = store

  if (axiosError.isAxiosError) {
    if (axiosError.message === 'NETWORK_ERROR') {
      // dispatch({
      //   type: ErrorActions.INTERNET_ERROR,
      //   message: 'error.network',
      // })
      return Promise.reject(error)
    }

    if (axiosError.response && axiosError.response.status === 500) {
      // dispatch({
      //   type: ErrorActions.INTERNAL_ERROR,
      //   message: 'error.internal',
      // })
      return Promise.reject(error)
    }

    if (axiosError.response && axiosError.response.status === 401) {
      //TODO:
      // if isAuth
      //   if token expired
      //     refresh token
      //   else
      //     logout
      // const token = getAuthToken()
      // if (token) {
      //   dispatch(logout())
      // }
      return Promise.reject(error)
    }
  }
  return Promise.reject(error)
}
serviceOptions.axios = instance
// export default instance
