import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AuthService, AccountDto, Member } from '@services/api'
import StorageService from '@services/storage'
import { AxiosError } from 'axios'
import { enqueueSnackbar } from './alertReducer'

export type AuthState = {
  isAuth: boolean
  account?: AccountDto
  user?: Member
}

export const initialState: AuthState = {
  isAuth: false,
}

export enum AuthActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}
export interface LoginValues {
  email: string
  password: string
  siteId: number
}

export const login = createAsyncThunk('auth/login', async (values: LoginValues, { dispatch }) => {
  try {
    const user = await AuthService.login({
      body: {
        email: values.email.toLowerCase().trim(),
        password: values.password.trim(),
      },
    })
    return user
  } catch (err) {
    const e = err as AxiosError
    if (e.response) {
      dispatch(enqueueSnackbar({ type: 'error', message: e.response.data.message }))
    } else {
      dispatch(enqueueSnackbar({ type: 'error', message: 'Network error' }))
    }
    throw e
  }
})

export const getCaptcha = createAsyncThunk('auth/captcha', async () => {
  return await AuthService.getCaptcha()
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      StorageService.removeAuthToken()
      state.user = undefined
      state.isAuth = false
      state.account = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
      StorageService.setAuthToken(action.payload.token)
      state.user = action.payload.user
      state.isAuth = true
    })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
