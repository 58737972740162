import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { CreateAnswerDto, QuestionService } from '../../services/api'

export const findAllQuestions = createAsyncThunk(
  Actions.QUESTION_FIND_ALL,
  async (params: { examId: number }, { rejectWithValue }) => {
    try {
      return await QuestionService.findAll(params)
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createAnswers = createAsyncThunk(
  Actions.QUESTION_ANSWER_CREATE,
  async (params: { medicalOrderId: number; body: CreateAnswerDto[] }, { rejectWithValue }) => {
    try {
      return await QuestionService.createAnswers(params)
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
