import { createContext, useEffect, useReducer } from 'react'
import { MemberState, MemberActions, memberReducer, initialState } from '../reducers/memberReducer'

export type MemberContext = {
  memberState: MemberState
  dispatch: React.Dispatch<AnyAction<MemberActions>>
}

//@ts-ignore
export const MemberContext = createContext<MemberContext | undefined>()

export function MemberContextProvider({ children }: any) {
  const [state, dispatch] = useReducer(memberReducer, initialState)

  useEffect(() => {
    // TODO Get list of members
  }, [])

  // return member ? (
  return (
    <MemberContext.Provider
      value={{
        memberState: state,
        dispatch,
      }}
    >
      {children}
    </MemberContext.Provider>
  )
}
