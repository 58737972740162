import { RootState } from '@state/store'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

export default function MainLayout({ children, ...props }: any) {
  const isAuth = useSelector(({ auth }: RootState) => auth.isAuth)

  if (isAuth) return <Redirect to={{ pathname: '/', state: { from: props.location } }} />

  return <main>{children}</main>
}
