import { Member } from '@services/api'

export interface MemberState {
  members: Member[]
  selectedMember: Member | null
}

export const initialState: MemberState = {
  members: [],
  selectedMember: null,
}

export enum MemberActions {
  FIND_MEMBERS = 'FIND_MEMBERS',
  SET_SELECTED_MEMBER = 'SET_SELECTED_MEMBER',
}

export function memberReducer(state = initialState, action: AnyAction<MemberActions>): MemberState {
  let selectedMember: Member | undefined
  switch (action.type) {
    case MemberActions.FIND_MEMBERS:
      console.log({ payload: action.payload })

      return { ...state, members: action.payload }

    case MemberActions.SET_SELECTED_MEMBER:
      selectedMember = state.members.find(_state => _state.id === action.payload)

      return { ...state, selectedMember: selectedMember || null }
  }
}
